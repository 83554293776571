<div class="tw-flex tw-flex-col tw-items-center tw-gap-6 tw-self-stretch tw-px-5 tw-py-8">
  <div class="tw-flex tw-flex-col tw-items-start tw-gap-2 tw-self-stretch">
    <div class="tw-flex tw-flex-col tw-items-start tw-gap-2 tw-self-stretch">
      <h3 class="tw-self-stretch tw-text-center tw-text-xl">Change Parent Event</h3>
    </div>
    <div>
      <div class="tw-flex tw-flex-col tw-gap-6 tw-self-stretch">
        <div class="tw-flex tw-flex-col tw-items-start tw-gap-8 tw-self-stretch">
          <div class="tw-text-center tw-text-sm">
            <p>Move this request to a different event with the same date. Choose the event from the dropdown and confirm your selection.</p>
            <p class="tw-mb-0 !tw-text-xs !tw-text-red-700">
              Important: If the original event has no requests after the change, it will be deleted. This action cannot be undone.
            </p>
          </div>
          <div class="tw-self-stretch">
            <label class="tw-mb-2 tw-block tw-font-bold tw-text-[#1E293B]">Move Request to...</label>
            <ate-singleselect
              placeholder="Select Event"
              [options]="_options | json"
              model-key="parentEvent"
              [value]="_value"
              (onValueChanges)="chooseParentEvent($event)"></ate-singleselect>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="footer tw-flex tw-items-center tw-justify-center tw-gap-4 tw-self-stretch tw-p-4">
  <div class="tw-flex tw-flex-[1_0_0] tw-items-center tw-gap-4 md:tw-flex-none">
    <button class="btn-action btn-action-md btn-secondary tw-flex tw-items-start" (click)="cancel()">
      <span>Cancel</span>
    </button>
    <button
      class="btn-action btn-action-md btn-primary tw-flex tw-flex-[1_0_0] tw-items-start md:tw-flex-none"
      (click)="changeParentEvent()"
      [disabled]="_isLoading || !_value">
      <div class="tw-flex tw-items-center tw-gap-2">
        <ate-icon
          *ngIf="_isLoading"
          class="!tw-size-[17px] tw-animate-spin tw-rounded-full tw-p-1 tw-text-white"
          name="loading-spinner-v3"
          slot="start"></ate-icon>
        <span>Confirm Change</span>
      </div>
    </button>
  </div>
</div>
