/* eslint-disable @typescript-eslint/no-explicit-any */
import { Inject, Injectable } from '@angular/core';
import { Observable, filter, first, fromEvent } from 'rxjs';
import { EventType } from '../event-request/event-request.type';
import { FormModel } from './form-model.interface';
import { AuthService } from '../auth/auth.service';
declare global {
  interface Window {
    ateV2OpenForm: (formLaunchConfig: { [key: string]: any }) => void;
  }
}

@Injectable()
export class EnhancedFormsLauncherService {
  private readonly localStorageAuthKey = 'enhancedFormsfirebaseAuthToken';

  constructor(
    @Inject('environment') private readonly environment,
    private readonly _authService: AuthService
  ) {}

  /**
   * Launch the Enhanced Form in a dialog on the current page.
   *
   * @param param0 Configuration for the Enhanced Forms
   */
  launchForm({ formUuid = '', eventUuid = '', mainEventUuid = '', userUuid = '', model = undefined, postcode = '' }) {
    this._authService
      .getAuthToken$()
      .pipe(
        first(),
        filter(token => !!token)
      )
      .subscribe({
        next: token => {
          this.saveAuthTokenToLocalStorage(token);

          window.ateV2OpenForm({
            formUuid: this.getFormName(mainEventUuid, formUuid, model),
            googlePlacesApiKey: this.environment.googleMapsApiKey,
            eventUuid,
            model,
            mainEventUuid,
            userUuid,
            postcode
          });
        },
        error: console.error
      });
  }

  onFormClose(): Observable<Event> {
    return fromEvent(window, 'onExitForm');
  }

  /**
   * Constructs an object that can be used to pre-populate the fields of the enhanced forms.
   * Can be expanded with the other fields found in the form definitions.
   *
   * @param event The event object that we can use to populate the model.
   */
  getModelFromEvent(event: EventType): FormModel {
    return { ...(event.description && { field_event_body: event.description ?? '' }) };
  }

  /**
   * Adds a suffix to the form UUID based on the parameters passed in.
   *
   * @param mainEventUuid The main event UUID - only provided if an event exists
   * @param formUuid The uuid of the form
   * @param model The previous answers for an initial form to be used for supplementary forms.
   *
   * @returns The full form UUID with any suffix included.
   */
  private getFormName(mainEventUuid, formUuid, model): string {
    // It must be a supplementary form because we already have the data.
    if (model) {
      return formUuid;
    }

    // It's a request for either an existing event or a new event.
    return `${formUuid}${mainEventUuid ? '-simplified' : '-simplified-new-event'}`;
  }

  /**
   * TODO: Automatically refresh the token in local storage on expiry.
   * @param token The auth token from Firebase.
   */
  private saveAuthTokenToLocalStorage(token: string) {
    console.log(`Auth token set at ${Date.now()}`);
    window.localStorage.setItem(this.localStorageAuthKey, token);
  }
}
