/* eslint-disable @typescript-eslint/no-explicit-any */
import { CUSTOM_ELEMENTS_SCHEMA, Component, Inject, OnInit, inject } from '@angular/core';

import { first } from 'rxjs';
import { StringToTitleCasePipe } from '../../../../shared/pipes/transform-string-to-uppercase/transform-string-to-uppercase.pipe';
import { CommonModule } from '@angular/common';
import { EventsService } from '../../../../shared/services/events/events.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FlashMessageService } from '../../../../shared/services/flash-message/flash-message.service';
import { FlashMessageType } from '../../../../shared/enums/flash-message-type.enum';
import { IMainEventData } from '../../../../shared/models/events';

const stringToTitleCasePipe = new StringToTitleCasePipe();

@Component({
  selector: 'app-change-parent-event-modal',
  templateUrl: './change-parent-event-modal.component.html',
  styleUrls: ['./change-parent-event-modal.component.scss'],
  imports: [CommonModule],
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ChangeParentEventModalComponent implements OnInit {
  public _options: any[] = [];
  public _value: string;
  _isLoading: boolean;
  readonly dialogRef = inject(MatDialogRef<ChangeParentEventModalComponent>);

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      eventUuid: string;
      eventDate: string;
      title: string;
      events: IMainEventData[];
    },
    private _eventsService: EventsService,
    private _flashMessageService: FlashMessageService
  ) {}

  ngOnInit(): void {
    this._options = this.data.events.map(event => {
      let label;
      const baseLabel = `${stringToTitleCasePipe.transform(event.mainEventType)}`;
      const activeRequestsLabel = `${baseLabel} • ${event.activeRequests[0]?.primaryServiceTitle}`;

      if (event.activeRequests.length === 0) {
        label = baseLabel;
      } else if (event.activeRequests.length === 1) {
        label = activeRequestsLabel;
      } else {
        label = `${activeRequestsLabel} + ${event.activeRequests.length - 1} other service${event.activeRequests.length - 1 > 1 ? 's' : ''}`;
      }

      return {
        label: label,
        value: event.mainEventUuid
      };
    });
  }

  chooseParentEvent(event) {
    this._value = event.detail.parentEvent.value;
  }

  changeParentEvent() {
    this._isLoading = true;
    this._eventsService
      .updateEvent(this.data.eventUuid, this._value)
      .pipe(first())
      .subscribe({
        next: () => {
          this._isLoading = false;
          this.dialogRef.close();
          this._flashMessageService.show({
            flashMessageType: FlashMessageType.Success,
            message: `You have successfully changed the parent event of ${this.data.title} request`
          });
        },
        error() {
          this._isLoading = false;
          this._flashMessageService.show({
            flashMessageType: FlashMessageType.Error,
            message: `Failed to change the parent event of ${this.data.title} request. Please try again`
          });
        }
      });
  }

  cancel() {
    this.dialogRef.close();
  }
}
